import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import config from './initializers/_config'
import App from './pages/App/App'
import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { frFR } from '@mui/material/locale'
import './services/system/I18n.service'
import { persistor, store } from './redux/store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'

config.init()

const theme = createTheme(
  {
    typography: {
      fontFamily: ['Comfortaa', 'cursive', 'Poppins', 'sans-serif'].join(','),
    },
    fontWeight: ['normal', 'bold'].join(','),
  },
  frFR,
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
