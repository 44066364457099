import { drawerConstants } from '../../constants/redux/drawer.constants'

const initialState = {
  open: false,
}
const drawer = (state = initialState, action) => {
  switch (action.type) {
    case drawerConstants.SHOW:
      return {
        open: true,
        ...action.payload,
      }
    case drawerConstants.CLOSE:
      return initialState

    default:
      return state
  }
}

export { drawer }
