export default {
  router: {
    login: '/connexion',
    home: '/',
    associations: '/associations',
    models: '/models',
    profile: '/profil',
    links: '/liens',
  },
  login: {
    email: 'Email',
    title: 'Se connecter avec Google workspace (@mob-ion.com)',
    emailErr: "L'email n'est pas valide",
    emailNotMob: "Veuillez saisir l'email mob-ion.",
    mainTitle: 'Accès à l’outil Mob-ion Toolbox via le compte Google Workspace',
    failed: "L'authentification a échoué",
  },
  global: {
    comboBoxLoading: 'Chargement ...',
    noOption: 'Aucun résultat',
    cancel: 'Annuler',
    save: 'Enregistrer',
    saveAdd: 'Enregistrer et Ajouter',
    add: 'Ajouter',
    export: ' Export SVG',
    loading: 'Chargement...',
    error: 'Erreur',
    actionOk: 'Action effectuée',
    actionDelete: 'supprimer avec succès',
    actionChange: 'Aucune modification détectée',
    box_text: 'Confirmer la suppression du association ',
    notFound: 'Aucun résultat',
    ok: 'Valider',
    refresh: 'Rafraîchir',
    search: 'Rechercher',
    reset: 'Réinitialiser',
    Active: 'Actif',
    Deleted: 'Supprimé',
  },
  table_assoc: {
    title: 'Associations',
    titleOne: 'Association',
    type: 'Type',
    info: 'Informations générales',
    status: 'Statut',
    creationDate: 'Créé le',
    refs: 'Références',
    comment: 'Commentaire',
    name: 'Nom',
    description: 'Description',
    updateDate: 'Modifié le',
    deletionDate: 'Supprimé le',
    creationUser: 'Créé par',
    updateUser: 'Modifié par',
    deletionUser: 'Supprimé par',
    history: 'Historique',
    date: 'Date',
    actions: 'Actions',
    btn_edit: 'Modifier',
    btn_delete: 'Supprimer',
    refKey: 'Clé référence',
    refValue: 'Valeur référence ',
    offset: 'Passer X résultats',
    limit: 'Limiter à X résultats',
    includeDeleted: 'Inclure les associations supprimées',
    refKeyFilter: 'Clé de référence (facultatif)',
    refValueFilter: 'Valeur de référence',
    addCsv: ' Ajouter (CSV)',
    download_example: 'Télécharger un exemple',
    download: 'Télécharger (CSV)',
  },
  t_body: {
    noMatch: 'Aucun résultat',
    toolTip: 'Trier',
    columnHeaderTooltip: 'Trier par',
  },
  t_pagination: {
    next: 'Page suivante',
    previous: 'Page précédente',
    toolTip: 'Page précédente',
    rowsPerPage: 'Lignes par page:',
    displayRows: 'de',
  },
  t_toolbar: {
    search: 'Chercher',
    downloadCsv: 'Télécharger CSV',
    print: 'Impression',
    viewColumns: 'Afficher les colonnes',
    filterTable: 'Tableau des filtres',
  },
  t_filter: {
    all: 'Toute',
    title: 'FILTRES',
    reset: 'RÉINITIALISER',
  },
  t_viewColumns: {
    title: 'Afficher les colonnes',
    titleAria: 'Afficher / masquer les colonnes du tableau',
  },
  t_selectedRows: {
    text: 'ligne(s) sélectionnée(s)',
    delete: 'Effacer',
    deleteAria: 'Supprimer les lignes sélectionnées',
  },
  drawer: {
    associations: 'Associations',
    profile: 'Mon profil',
    models: 'Modèles',
    home: 'Accueil ',
    links: 'Liens',
    login: 'Connexion',
    log_out: 'Déconnexion',
    log_outDialog: 'Voulez-vous vraiment vous déconnecter ?',
  },
  models: {
    title: 'Modèles',
    titleOne: 'Modèle',
    example: 'Exemple',
    webHook: 'Webhooks',
    webHookBtn: 'Ajouter des Webhook',
    description: 'Description',
    comment: 'Commentaire',
    key: 'Clé',
    typeRequire: 'Type est requis',
    refsLength: '2 références au minimum sont nécessaires.',
    refsR: 'Les références sont requises',
    nameRequire: 'Nom est requis',
    tableFilter: 'Filtres',
    search: 'Rechercher',
    includeDeleted: 'Inclure les modèles supprimés',
    name: 'Nom',
    refsKey: 'Clé référence',
    refsValue: 'Valeur référence',
    valuePlaceholderExample: '{{placeholder}} (ex: {{example}})',
    refsValuePlaceholder: 'Valeur référence ({{value}})',
    errorRefsValue: 'Formulaire incorrect.',
    errorRegEx: 'Invalide expression régulière (regEx)',
    requiredLength: '2 références au minimum sont obligatoire.',
    user: 'Utilisateur',
    regex: 'Regex',
    placeholder: 'Libellé',
    value: 'Valeur',
    switch: 'Obligatoire',
  },
  profile: {
    title: 'Mon profil',
    lastName: 'Nom :',
    firstName: 'Prénom :',
    email: 'Email :',
    developerView: 'Mode développeur :',
  },
  header: {
    profile: 'Profile',
    language: 'Langue',
  },
  links: {
    title: 'Liens',
    titleOne: 'Lien',
    link: 'Lien',
    cible: 'Cible',
    qrcode: 'Qrcode',
    description: 'Description',
    creationDate: 'Date de création',
    updateDate: 'Date de modification',
    deleteText: 'Supprimer ce lien ?',
    cibleRequire: 'Cible est requis',
    linkAdd: 'Lien générer',
    linkAddHelpers: 'Lien généré: {{value}}',
    actions: 'Actions',
    code: 'Code unique',
    copy: 'Copié dans le presse-papier',
    clicked: 'Total de visites',
  },
  linkReport: {
    title: 'Historique de visites',
    creationDate: 'Date de création',
    lastClikedDate: 'Date de Dernière visite',
    cliked: 'Visites',
    creationIp: 'Adresse IP',
  },
  history: {
    updateDate: 'Modifié le : {{value}}',
  },
  dropZone: {
    msg: ' Joindre un fichier - Glisser  (image, vidéo, pdf)',
    msgPicture: ' Joindre un fichier - Glisser',
    msgBtn: 'Choisir un fichier',
    msgAvatar: 'Choisir un avatar',
    unsupportedTypes: 'Fichier non supporté : {{value}}',
    fileError: '{{value}} fichier non pris en charge',
    fileError_plural: '{{value}} fichiers non pris en charge',
    fileOk: '{{value}} fichier  joint',
    fileOk_plural: '{{value}} fichiers joints',
    upload: 'Upload Files',
    size: 'Fichier trop volumineux (20mo max)',
    duplicate: 'Fichier déjà ajouté : {{value}}',
  },
  documents: {
    files: 'Pièces jointes',
    notHaveFile: 'Aucune pièce jointe',
  },
  webHook: {
    name: 'Nom',
    nameTable: 'Nom : {{name}}',
    url: 'Url',
    method: 'Method',
    event: 'Event',
    hooksTitle: 'Webhooks',
    creationDate: 'Créé le',
    creationUser: 'Créé par',
    box_text: 'Confirmer la suppression du webHook ',
    copy: 'Option de copie non disponible pour ce navigateur',
  },
  qrCode: {
    title: 'QrCode',
    btnDownload: 'Télécharger le code QR',
    svg: 'SVG',
    jpeg: 'JPEG',
    png: 'PNG',
    exportFormat: "Format d'export",
    info: 'Générales',
    size: 'Taille',
    bgColor: "Couleur de l'arrière plan",
    fgColor: 'Couleur de premier plan',
    qrStyle: 'Qr code Style',
    cors: 'Activer CORS',
    ecLevel: 'Niveau',
    quietZone: 'Quiet zone',
    showLogoMobion: 'Mob-ion',
    removeQrCodeBehindLogo: 'Supprimer autour',
    logoImage: 'Image',
    logoWidth: 'Largeur',
    logoHeight: 'Hauteur',
    logoOpacity: 'Opacité',
    eye: 'Oeil',
    eyeColorOuter: 'Couleur extérieure',
    topLeft: 'En haut à gauche',
    outerEyeRadius: 'Rayon externe',
    eyeColorInner: 'Couleur intérieure',
    innerEyeRadius: 'Rayon intérieur',
    topRight: 'En haut à droite',
    bottomLeft: 'En bas à gauche',
    logo: 'Logo',
    export: 'Exportation',
  },
}
