/* eslint-disable no-shadow */

const init = () => {
  // eslint-disable-next-line no-undef
  const envName = process.env.REACT_APP_CONFIG_NAME

  if (!envName) {
    throw new Error('REACT_APP_CONFIG_NAME env variable is missing')
  }

  import(`../../config/config.${envName}.js`)
    .then((config) => {
      // eslint-disable-next-line no-undef
      global.CONFIG = config
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err)
    })
}

export default { init }
