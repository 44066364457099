import { alertConstants } from '../../constants/redux/alert.constants'

const initialState = {
  open: false,
  message: null,
  type: null,
  autoHideDuration: null,
}

const alert = (state = initialState, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        open: true,
        autoHideDuration: action.payload.autoHideDuration,
        message: action.payload.message,
      }
    case alertConstants.ERROR:
      return {
        open: true,
        type: 'error',
        autoHideDuration: action.payload.autoHideDuration,
        message: action.payload.message,
      }

    case alertConstants.INFO:
      return {
        open: true,
        type: 'info',
        autoHideDuration: action.payload.autoHideDuration,
        message: action.payload.message,
      }
    case alertConstants.CLEAR:
      return initialState
    default:
      return state
  }
}

export { alert }
