import { makeStyles } from '@mui/styles'
import { createTheme } from '@mui/material/styles'
const theme = createTheme()

const StylesWithMaterialUi = makeStyles(() => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(20),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(20),
    },
  },
}))

export default StylesWithMaterialUi
