import { linksConstants } from '../../constants/redux/links.contants'

const initialState = {
  data: null,
  isLoading: false,
  isError: false,
}

const links = (state = initialState, action) => {
  switch (action.type) {
    case linksConstants.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case linksConstants.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      }
    case linksConstants.FAILURE:
      return {
        isLoading: true,
        isError: true,
      }
    case linksConstants.CLEAR:
      return initialState
    default:
      return state
  }
}

export { links }
