import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

import { authentication } from '../reducers/authentication.reducer'
import { drawer } from '../reducers/drawer.reducer'
import { alert } from '../reducers/alert.reducer'
import { associations } from '../reducers/associations.reducer'
import { models } from '../reducers/models.reducer'
import { links } from '../reducers/links.reducer'

const persistenceConfigs = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
}

const rootReducer = combineReducers({
  authentication,
  drawer,
  alert,
  associations,
  models,
  links,
})
const middlewares = []

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require, no-undef
  const { logger } = require(`redux-logger`)

  middlewares.push(logger)
}
const persistedReducer = persistReducer(persistenceConfigs, rootReducer)
const store = createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware, ...middlewares),
)
const persistor = persistStore(store)

export { persistor, store }
