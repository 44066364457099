import { makeStyles } from '@mui/styles'
import { createTheme } from '@mui/material/styles'
const theme = createTheme()
const StylesWithMaterialUi = makeStyles(() => ({
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
}))
export default StylesWithMaterialUi
