import { authenticationConstants } from '../../constants/redux/authentication.constants'

const user = localStorage.getItem('user')
const developerView = localStorage.getItem('developerView')
const initialState = user
  ? {
      loggedIn: true,
      user: JSON.parse(user),
      developerView,
      model: { status: false, callback: null },
    }
  : {
      user: null,
      model: { status: false, callback: null },
      loggedIn: false,
      developerView,
    }

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case authenticationConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: false,
        user: action.payload.user,
      }
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
      }
    case authenticationConstants.LOGIN_MODEL:
      return {
        ...state,
        model: {
          status: action.payload.status,
          callback: action.payload.callback,
        },
      }
    case authenticationConstants.USER_UPDATE:
      return {
        ...state,
        developerView: action.payload.checked,
      }
    case authenticationConstants.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        loggedIn: false,
      }
    case authenticationConstants.LOGOUT:
      return {
        ...state,
        user: null,
        loggedIn: false,
      }
    default:
      return state
  }
}

export { authentication }
