import { associationsConstants } from '../../constants/redux/associations.contants'

const initialState = {
  data: null,
  isLoading: false,
  isError: false,
}

const associations = (state = initialState, action) => {
  switch (action.type) {
    case associationsConstants.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case associationsConstants.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      }
    case associationsConstants.FAILURE:
      return {
        isLoading: true,
        isError: true,
      }
    case associationsConstants.CLEAR:
      return initialState
    default:
      return state
  }
}

export { associations }
